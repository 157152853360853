var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"page"}},[_c('section',{staticClass:"product-list-heading box-my-account mb-0"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('ProfileNavbar',{attrs:{"parentData":_vm.userData.full_name}}),_c('div',{staticClass:"col-md-9 py-5 px-lg-5",attrs:{"id":"change-password"}},[_c('form',{staticClass:"mt-4",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('h2',[_vm._v("Change Password")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('MyInputFormText',{attrs:{"id":"old-password","label":"Current Password","type":"password","classComponent":{
                    'is-invalid-custom': _vm.$v.old_password.$error,
                  },"invalidMessage":[
                    !_vm.$v.old_password.required
                      ? 'Butuh kata sandi kamu nih'
                      : null,
                  ]},model:{value:(_vm.$v.old_password.$model),callback:function ($$v) {_vm.$set(_vm.$v.old_password, "$model", $$v)},expression:"$v.old_password.$model"}}),_c('MyInputFormText',{attrs:{"id":"new-password","label":"New Password","type":"password","classComponent":{
                    'is-invalid-custom': _vm.$v.new_password.$error,
                  },"invalidMessage":[
                    !_vm.$v.new_password.required
                      ? 'Butuh kata sandi baru kamu nih'
                      : null,
                    !_vm.$v.new_password.passwordPattern
                      ? 'Kata sandi baru kamu terlalu lemah, coba tambahin huruf dan angka yaa'
                      : null,
                    !_vm.$v.new_password.minLength
                      ? `Kata sandi baru kamu terlalu pendek nih, panjang teks minimum ${_vm.$v.new_password.$params.minLength.min}.`
                      : null,
                  ]},model:{value:(_vm.$v.new_password.$model),callback:function ($$v) {_vm.$set(_vm.$v.new_password, "$model", $$v)},expression:"$v.new_password.$model"}}),_c('MyInputFormText',{attrs:{"id":"confirm-password","label":"Confirm Password","type":"password","classComponent":{
                    'is-invalid-custom': _vm.$v.confirm_password.$error,
                  },"invalidMessage":[
                    !_vm.$v.confirm_password.required
                      ? 'Butuh konfirmasi kata sandi kamu nih'
                      : null,
                    !_vm.$v.confirm_password.sameAsPassword &&
                    _vm.$v.confirm_password.required
                      ? 'Kata sandi kamu harus cocok ya ..'
                      : null,
                  ]},model:{value:(_vm.$v.confirm_password.$model),callback:function ($$v) {_vm.$set(_vm.$v.confirm_password, "$model", $$v)},expression:"$v.confirm_password.$model"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group text-lg-left text-center"},[_c('button',{staticClass:"btn btn-wz-proceed",attrs:{"type":"submit"}},[_vm._v(" Save Change "),(_vm.isLoading)?_c('font-awesome',{attrs:{"icon":"spinner","spin":""}}):_vm._e()],1)])])])])])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }