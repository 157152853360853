<template>
  <div id="page">
    <section class="product-list-heading box-my-account mb-0">
      <div class="container-fluid">
        <div class="row">
          <ProfileNavbar :parentData="userData.full_name"></ProfileNavbar>
          <div id="change-password" class="col-md-9 py-5 px-lg-5">
            <form @submit.prevent="handleSubmit" class="mt-4">
              <h2>Change Password</h2>
              <div class="row">
                <div class="col-md-6">
                  <!-- Current Password -->
                  <MyInputFormText
                    id="old-password"
                    label="Current Password"
                    type="password"
                    v-model="$v.old_password.$model"
                    :classComponent="{
                      'is-invalid-custom': $v.old_password.$error,
                    }"
                    :invalidMessage="[
                      !$v.old_password.required
                        ? 'Butuh kata sandi kamu nih'
                        : null,
                    ]"
                  />
                  <!-- New Password -->
                  <MyInputFormText
                    id="new-password"
                    label="New Password"
                    type="password"
                    v-model="$v.new_password.$model"
                    :classComponent="{
                      'is-invalid-custom': $v.new_password.$error,
                    }"
                    :invalidMessage="[
                      !$v.new_password.required
                        ? 'Butuh kata sandi baru kamu nih'
                        : null,
                      !$v.new_password.passwordPattern
                        ? 'Kata sandi baru kamu terlalu lemah, coba tambahin huruf dan angka yaa'
                        : null,
                      !$v.new_password.minLength
                        ? `Kata sandi baru kamu terlalu pendek nih, panjang teks minimum ${$v.new_password.$params.minLength.min}.`
                        : null,
                    ]"
                  />
                  <!-- Confirm New Password -->
                  <MyInputFormText
                    id="confirm-password"
                    label="Confirm Password"
                    type="password"
                    v-model="$v.confirm_password.$model"
                    :classComponent="{
                      'is-invalid-custom': $v.confirm_password.$error,
                    }"
                    :invalidMessage="[
                      !$v.confirm_password.required
                        ? 'Butuh konfirmasi kata sandi kamu nih'
                        : null,
                      !$v.confirm_password.sameAsPassword &&
                      $v.confirm_password.required
                        ? 'Kata sandi kamu harus cocok ya ..'
                        : null,
                    ]"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group text-lg-left text-center">
                    <button type="submit" class="btn btn-wz-proceed">
                      Save Change
                      <font-awesome v-if="isLoading" icon="spinner" spin />
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { toaster, regex } from "@/_helpers";

import ProfileNavbar from "@/components/mains/ProfileNavbar.vue";
import MyInputFormText from "@/components/form/MyInputFormText";

import { required, minLength, sameAs, helpers } from "vuelidate/lib/validators";

// Regex for Validation
const passwordPattern = helpers.regex("passwordPattern", regex.passwordPattern);

export default {
  name: "ChangePasswordProfile",
  components: {
    ProfileNavbar,
    MyInputFormText,
  },
  data() {
    return {
      old_password: "",
      new_password: "",
      confirm_password: "",
      isLoading: false,
      token: this.$store.getters.getUserToken,
    };
  },
  validations: {
    old_password: {
      required,
    },
    new_password: {
      required,
      minLength: minLength(6),
      passwordPattern,
    },
    confirm_password: {
      required,
      sameAsPassword: sameAs("new_password"),
    },
  },
  computed: {
    userData() {
      return this.$store.getters.getUserData;
    },
  },
  methods: {
    async handleSubmit() {
      try {
        this.isLoading = true;
        this.$v.$touch();
        if (this.$v.$error) {
          toaster.make("Please fill the form correctly", "danger");
          const errors = Object.keys(this.$v)
            .map((el) => {
              if (!/^\$/.test(el))
                return {
                  form: el,
                  data: this.$v[el],
                };
            })
            .filter((el) => el && el.data.$invalid);
          console.error("Invalid form data", errors);
          this.isLoading = false;
          this.scrollToTop();
        } else {
          const data = {
            old_password: this.old_password,
            new_password: this.new_password,
            confirm_password: this.confirm_password,
          };
          const res = await this.$api.user.changePassword(data);
          if (res.status === 200) {
            toaster.make("Password change successfully", "success");
            this.$router.push("/profile");
          } else this.$helpers.toaster.make(res.data.message, "danger");
          this.isLoading = false;
        }
      } catch (e) {
        toaster.make(e.response.data.message, "danger");
        console.error(e);
        this.isLoading = false;
      }
    },
    scrollToTop() {
      const yOffset = -70;
      const element = document.getElementById("change-password");
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    },
  },
};
</script>
